import { ReactElement } from 'react';

export type AppRouteHasPermissionsGuardProps = {
  /**
   * Permissions list that need to be granted for user to be able to go by route
   */
  permissions: string[];
  /**
   * boolean flag to check any or all permissions from the list
   * permission guards will use true as default value if not specified.
   */
  any?: boolean;
};

export type AppRoutesProps = {
  path: string;
  groups?: RoleGroups[];
  title: string;
  /**
   * Icon for a menu item
   * String type is deprecated, use ReactElement
   */
  icon?: string | ReactElement;
  parent?: string;
  hidden?: boolean;
  hasPermissions?: AppRouteHasPermissionsGuardProps;
  subRoutes?: AppRoutesProps[];
};

type ScreenSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type RoleGroups = 'vendors' | 'debtors' | 'merchants' | 'manager' | 'billingRequestApprovers';

const mobileScreens: ScreenSizes[] = ['xs'];
const desktopReadyConfig = {
  '*': mobileScreens,
  '/logout': undefined,
};

export const deniedScreenSizesByRole: Record<RoleGroups, Record<string, ScreenSizes[] | undefined> | undefined> = {
  manager: desktopReadyConfig,
  vendors: desktopReadyConfig,
  debtors: desktopReadyConfig,
  billingRequestApprovers: desktopReadyConfig,
  merchants: undefined,
};
